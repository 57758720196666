export const environment = {
  production: true,
  appVersion: "v4.0",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: true,
  type: "production",
  sentry_dsn:
    "https://0fd4dce63f8adf8ea535bb7931d577af@o4505907318816768.ingest.sentry.io/4506382628159488",
  traces_sample_rate: 0.5,
  replays_on_error_sample_rate: 0.5,
  replays_session_sample_rate: 0.1,
  api: {
    url: "https://api.askeddi.com",
  },
  msal: {
    tenant: "common",
    clientId: "a7b9110f-1ab4-450b-822c-c2f7b9547ba3",
    redirectUri: "https://portal.askeddi.com/auth/login",
  },
  stripe_pk: "pk_test_Z0nuedUSwnp544suhN6JJSJH00ShgsI4Lk",
  google: {
    clientId:
      "207582784092-tolhdje7iv7seu37am7a9orjmqfj1q8a.apps.googleusercontent.com",
  },
};
